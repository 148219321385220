import React, { Component } from "react";

import { Button, Col, Input, Popconfirm, Row, Space, Table } from "antd";
import { connect } from "react-redux";
import { TPReturnList, TPReturnPick } from "../../../action/parcel";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import Header from "../../../components/header";
import ReactToPrint from "react-to-print";
import amvines from "./amvinesStickerLogo.png";
import QRCode from "qrcode.react";
import Barcode from "react-barcode";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { InvoiceContainer } from "./invoice.style";
import styled from "styled-components";
import returned from "./returned.png";

const Watermark = styled.img`
  position: absolute;
  width: 100px;
  /* height: 100px; */
  top: 150px;
  left: 40%;
  opacity: 0.5;
  /* transform: rotate(15deg); */
`;
class PrintBarCode extends Component {
  state = {
    invoice: JSON.parse(localStorage.getItem("invoice")),
  };
  printDocument() {
    const input = document.getElementById("divToPrint");
    html2canvas(input).then((canvas) => {
      let imgWidth = 208;
      let imgHeight = (canvas.height * imgWidth) / canvas.width;
      const imgData = canvas.toDataURL("img/png");
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      // pdf.output('dataurlnewwindow');
      pdf.save(
        JSON.parse(localStorage.getItem("invoice")).order_date.split("T")[0] +
          "---" +
          JSON.parse(localStorage.getItem("invoice")).parcel_id
      );
    });
  }
  render() {
    const { invoice } = this.state;
    var today = new Date();
    const date = invoice.order_date.split("T")[0];
    const t = invoice.order_date.split("T")[1];
    const time = t.split(".")[0];
    const hour = (today.getHours() + 24) % 12 || 12;
    const orderDate = new Date(invoice.order_date);
    return (
      <>
        <InvoiceContainer id="divToPrint" style={{ padding: "1px" }}>
          <Watermark src={returned} />
          <Row gutter={2} style={{ margin: "0px", padding: "0px" }}>
            <Col xs={10} style={{ borderRight: "1px solid #000" }}>
              <img src={amvines} />
            </Col>
            <Col xs={3}></Col>
            <Col xs={10}>
              <p style={{ fontSize: "10px" }}>
                Returing to {invoice.shop_name}
              </p>
              <p style={{ fontSize: "10px" }}>{invoice.shop_mobile}</p>
              <p style={{ fontSize: "10px" }}>{date + " " + time}</p>
            </Col>
          </Row>

          {/* ===========Info== */}

          <Row gutter={0} style={{ margin: "0px" }}>
            <Col xs={24}>
              <h5>Customer Details</h5>
            </Col>
            <Col xs={4} style={{ padding: "0px" }}>
              <p>Name</p>
            </Col>
            <Col xs={20}>
              <p style={{ fontWeight: "bold" }}>: {invoice.customer_name}</p>
            </Col>
            <Col xs={4} style={{ padding: "0px" }}>
              <p>Phone</p>
            </Col>
            <Col xs={20}>
              <p style={{ fontWeight: "bold" }}>: {invoice.customer_phone}</p>
            </Col>
            <Col xs={4} style={{ padding: "0px" }}>
              <p>Address</p>
            </Col>
            <Col xs={20}>
              <p style={{ fontWeight: "bold" }}>: {invoice.customer_address}</p>
            </Col>
            <br />
            <Col xs={24}>
              <h5>Product Details</h5>
            </Col>
            <Col xs={4} style={{ padding: "0px" }}>
              <p>Description</p>
            </Col>
            <Col xs={20}>
              <p>: {invoice.product_description}</p>
            </Col>
            <Col xs={4} style={{ padding: "0px" }}>
              <p>Items</p>
            </Col>
            <Col item xs={20}>
              {" "}
              <p>: {invoice.item}</p>
            </Col>
          </Row>

          <Row
            gutter={0}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "5px",
            }}
          >
            <Col xs={8}>
              <QRCode value={localStorage.getItem("barcode")} size={55} />
            </Col>
            <Col xs={12}>
              <Barcode
                value={localStorage.getItem("barcode")}
                width="1"
                height="30"
                text={invoice.parcel_id}
                fontSize="10px"
                fontWeight="bold"
              />
            </Col>
          </Row>

          <Row
            container
            spacing={1}
            style={{ fontSize: "10px", fontWeight: "bold", margin: "0px" }}
          >
            <Col item xs={12}>
              {" "}
              {localStorage.getItem("user_id")}{" "}
            </Col>
            <Col item xs={12} style={{ textAlign: "right" }}>
              {today.getFullYear() +
                "-" +
                (today.getMonth() + 1) +
                "-" +
                today.getDate() +
                " " +
                today.getHours() +
                ":" +
                today.getMinutes() +
                ":" +
                today.getSeconds()}{" "}
            </Col>
          </Row>
        </InvoiceContainer>
        <button
          onClick={this.printDocument}
          style={{
            cursor: "pointer",
            backgroundColor: "#06aa4a",
            padding: "12px 185px",
            textDecoration: "none",
            color: "#fff",
          }}
        >
          Download
        </button>
      </>
    );
  }
}

class CollectReturnParcels extends Component {
  state = {
    searchText: "",
    searchedColumn: "",
    data: [],
  };
  componentDidMount = () => {
    this.getData();
  };
  getData = async () => {
    const res = await this.props.TPReturnList();
    this.setState({ data: res });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
            this.setState({
              searchText: selectedKeys[0],
              searchedColumn: dataIndex,
            });
          }}
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  createData = (
    order_id,
    return_order_id,
    tp_id,
    tp_name,
    pending_payment,
    status,
    shop,
    created_at,
    action
  ) => {
    return {
      order_id,
      return_order_id,
      tp_id,
      tp_name,
      pending_payment,
      status,
      shop,
      created_at,
      action,
    };
  };

  makeLocalTime = (date) => {
    let mainDate = date.split("T")[0];
    let hours = date.split("T")[1].split(":")[0];
    let minutes = date.split("T")[1].split(":")[1];
    var am = true;
    if (hours > 12) {
      am = false;
      hours -= 12;
    } else if (hours == 12) {
      am = false;
    } else if (hours == 0) {
      hours = 12;
    }
    return (
      mainDate + " , " + hours + ":" + minutes + " " + (am ? "a.m" : "p.m")
    );
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  handlePickup = async (data) => {
    const res = await this.props.TPReturnPick(data);
    if (res === 201) {
      this.getData();
    }
  };

  render() {
    const columns = [
      {
        title: "Order ID",
        dataIndex: "order_id",
        key: "order_id",
        width: "15%",
        ...this.getColumnSearchProps("order_id"),
      },
      {
        title: "Return Order ID",
        dataIndex: "return_order_id",
        key: "return_order_id",
        width: "15%",
        ...this.getColumnSearchProps("return_order_id"),
      },
      {
        title: "Third Party ID",
        dataIndex: "tp_id",
        key: "tp_id",
        width: "15%",
        ...this.getColumnSearchProps("tp_id"),
      },
      {
        title: "Third Party Name",
        dataIndex: "tp_name",
        key: "tp_name",
        width: "10%",
        ...this.getColumnSearchProps("tp_name"),
      },
      {
        title: "Pending Payment",
        dataIndex: "pending_payment",
        key: "pending_payment",
        width: "10%",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: "10%",
        ...this.getColumnSearchProps("status"),
      },
      {
        title: "Shop Info",
        dataIndex: "shop",
        key: "shop",
        width: "20%",
        ...this.getColumnSearchProps("shop"),
      },
      {
        title: "Create Time",
        dataIndex: "created_at",
        key: "created_at",
        width: "12%",
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        width: "12%",
      },
    ];
    const { data } = this.state;
    const rows = [
      ...(data
        ? data?.map((item, id) =>
            this.createData(
              item.order_id,
              item?.return_order_id?.order_id,
              item?.tp_id,
              item?.tp_name,
              item?.pending_payment ? "True" : "Fasle",
              item?.state,
              item?.shop.name + "---" + item?.shop?.address,
              this.makeLocalTime(item?.created_at),
              <Button type="primary" onClick={() => this.handlePickup(item)}>
                Pick
              </Button>
            )
          )
        : ""),
    ];

    return (
      <>
        <Header
          title="Collect Return Order"
          subtitle="Third Party"
          name="third_party"
        />

        {localStorage.getItem("barcode") ? (
          <div style={{ width: "35%", margin: "auto" }}>
            <PrintBarCode ref={(el) => (this.PrintBarCode = el)} />
            <span
              onClick={() => {
                this.setState({ receipt_no: "" });
              }}
            >
              <ReactToPrint
                trigger={() => (
                  <a
                    href="#"
                    style={{
                      position: "relative",
                      top: "20px",
                      backgroundColor: "#3498db",
                      padding: "12px 170px",
                      textDecoration: "none",
                      color: "#fff",
                    }}
                  >
                    Print this out!
                  </a>
                )}
                content={() => this.PrintBarCode}
              />
            </span>
            <br />
            <br />
            <br />
            <br />
            <a
              href="#"
              onClick={() => {
                localStorage.removeItem("barcode");
                localStorage.removeItem("invoice");
              }}
              style={{
                backgroundColor: "#e74c3c",
                padding: "12px 200px",
                textDecoration: "none",
                color: "#fff",
              }}
            >
              Close
            </a>
          </div>
        ) : null}
        {rows?.length > 0 ? (
          <Table
            columns={columns}
            dataSource={rows}
            scroll={{ x: 1000 }}
            sticky
          />
        ) : null}
      </>
    );
  }
}

export default connect(null, { TPReturnList, TPReturnPick })(
  CollectReturnParcels
);
