import axios from "axios";

export default (token = false) => {
  return axios.create({
    baseURL: "https://api.amvines.hidayahsmart.solutions/warehouse/",
    // baseURL: 'http://api.bahondemo1.hidayahsmart.solutions/warehouse/',
    headers: {
      "content-type": "application/json",

      authorization: `Bearer ${token}`,
    },
  });
};
