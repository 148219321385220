import React, { Component } from "react";

import {
  Button,
  Col,
  Empty,
  Form,
  Grid,
  Input,
  Modal,
  Row,
  Select,
  Table,
} from "antd";
import { connect } from "react-redux";
import {
  getWarehouseReturnOrder,
  confirmManualReturnOrder,
} from "../../../../action/parcel";
import { fetchDrivers } from "../../../../action/drivers";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import Header from "../../../../components/header";
import ReactToPrint from "react-to-print";
import amvines from "./amvinesStickerLogo.png";
import QRCode from "qrcode.react";
import Barcode from "react-barcode";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { InvoiceContainer } from "./invoice.style";

const { Option } = Select;

class PrintBarCode extends Component {
  state = {
    invoice: JSON.parse(localStorage.getItem("invoice")),
  };
  printDocument() {
    const input = document.getElementById("divToPrint");
    html2canvas(input).then((canvas) => {
      let imgWidth = 208;
      let imgHeight = (canvas.height * imgWidth) / canvas.width;
      const imgData = canvas.toDataURL("img/png");
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      // pdf.output('dataurlnewwindow');
      pdf.save(
        JSON.parse(localStorage.getItem("invoice")).order_date.split("T")[0] +
          "---" +
          JSON.parse(localStorage.getItem("invoice")).parcel_id
      );
    });
  }
  render() {
    const { invoice } = this.state;
    var today = new Date();
    const date = invoice.order_date.split("T")[0];
    const t = invoice.order_date.split("T")[1];
    const time = t.split(".")[0];
    const hour = (today.getHours() + 24) % 12 || 12;
    const orderDate = new Date(invoice.order_date);
    return (
      <>
        <InvoiceContainer id="divToPrint" style={{ padding: "1px" }}>
          <div style={{ margin: "10px" }}>
            <Row gutter={2} style={{ margin: "0px", padding: "0px" }}>
              <Col xs={12} style={{ borderRight: "1px solid #000" }}>
                <img src={amvines} />
              </Col>
              <Col xs={12} style={{ paddingLeft: "5px" }}>
                <p style={{ fontSize: "10px" }}>{invoice.shop_name}</p>
                <p style={{ fontSize: "10px" }}>{invoice.shop_mobile}</p>
                <p style={{ fontSize: "10px" }}>
                  Invoice : {invoice.merchant_order_id}
                </p>
                <p style={{ fontSize: "10px" }}>{date + " " + time}</p>
              </Col>
            </Row>

            {/* ===========Info== */}

            <Row gutter={0} style={{ margin: "0px" }}>
              <Col xs={24}>
                <h5 style={{ fontWeight: "bold" }}>Customer Details</h5>
              </Col>
              <Col xs={4} style={{ padding: "0px" }}>
                <p>Name</p>
              </Col>
              <Col xs={20}>
                <p style={{ fontWeight: "bold" }}>: {invoice.customer_name}</p>
              </Col>
              <Col xs={4} style={{ padding: "0px" }}>
                <p>Phone</p>
              </Col>
              <Col xs={20}>
                <p style={{ fontWeight: "bold" }}>: {invoice.customer_phone}</p>
              </Col>
              <Col xs={4} style={{ padding: "0px" }}>
                <p>Address</p>
              </Col>
              <Col xs={20}>
                <p style={{ fontWeight: "bold" }}>
                  : {invoice.customer_address}
                </p>
              </Col>
              <br />
              <Col xs={24}>
                <h5 style={{ fontWeight: "bold" }}>Product Details</h5>
              </Col>
              <Col xs={4} style={{ padding: "0px" }}>
                <p>Description</p>
              </Col>
              <Col xs={20}>
                <p>: {invoice.product_description}</p>
              </Col>
              <Col xs={4} style={{ padding: "0px" }}>
                <p>Items</p>
              </Col>
              <Col item xs={20}>
                {" "}
                <p>: {invoice.item}</p>
              </Col>
            </Row>

            <Row
              gutter={0}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "5px",
              }}
            >
              <Col xs={8}>
                <QRCode value={localStorage.getItem("barcode")} size={55} />
              </Col>
              <Col xs={12}>
                <Barcode
                  value={localStorage.getItem("barcode")}
                  width="1"
                  height="30"
                  text={invoice.parcel_id}
                  fontSize="10px"
                  fontWeight="bold"
                />
              </Col>
            </Row>

            <Row
              container
              spacing={1}
              style={{ fontSize: "10px", fontWeight: "bold", margin: "0px" }}
            >
              <Col item xs={12}>
                {" "}
                {localStorage.getItem("user_id")}{" "}
              </Col>
              <Col item xs={12} style={{ textAlign: "right" }}>
                {today.getFullYear() +
                  "-" +
                  (today.getMonth() + 1) +
                  "-" +
                  today.getDate() +
                  " " +
                  today.getHours() +
                  ":" +
                  today.getMinutes() +
                  ":" +
                  today.getSeconds()}{" "}
              </Col>
            </Row>
          </div>
        </InvoiceContainer>
        <button
          onClick={this.printDocument}
          style={{
            cursor: "pointer",
            backgroundColor: "#06aa4a",
            padding: "12px 185px",
            textDecoration: "none",
            color: "#fff",
          }}
        >
          Download
        </button>
      </>
    );
  }
}

// ============================================
class WarehouseReturn extends Component {
  state = {
    searchText: "",
    searchedColumn: "",
    data: [],
    weight: "",
    r_no: "",
    drivers: "",
    rider: "",
    visible: true,
  };
  componentDidMount = () => {
    this.getData();
    this.getDrivers();
  };
  getData = async () => {
    const res = await this.props.getWarehouseReturnOrder();
    this.setState({ data: res });
  };
  getDrivers = async () => {
    const res = await this.props.fetchDrivers();
    this.setState({ drivers: res });
  };

  createOption = (label, value) => {
    return { label, value };
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
            this.setState({
              searchText: selectedKeys[0],
              searchedColumn: dataIndex,
            });
          }}
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  createData = (
    order_id,
    merchant_order_id,
    store_info,
    return_info,
    order_time,
    action
  ) => {
    return {
      order_id,
      merchant_order_id,
      store_info,
      return_info,
      order_time,
      action,
    };
  };

  makeLocalTime = (date) => {
    let mainDate = date.split("T")[0];
    let hours = date.split("T")[1].split(":")[0];
    let minutes = date.split("T")[1].split(":")[1];
    var am = true;
    if (hours > 12) {
      am = false;
      hours -= 12;
    } else if (hours == 12) {
      am = false;
    } else if (hours == 0) {
      hours = 12;
    }
    return (
      mainDate + " , " + hours + ":" + minutes + " " + (am ? "a.m" : "p.m")
    );
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  handleSubmit = async (data) => {
    localStorage.removeItem("invoice");
    localStorage.removeItem("barcode");
    const res = await this.props.confirmManualReturnOrder(data);
    if (res === 201) {
      this.setState({ visible: true });
      this.getData();
    }
  };

  onFinish = ({ rider, order_id }) => {
    this.props.fetchSingleReturn(order_id, rider);
  };

  handleOk = () => {
    this.setState({ visible: false });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };
  render() {
    const columns = [
      {
        title: "Order ID",
        dataIndex: "order_id",
        key: "order_id",
        width: "15%",
        ...this.getColumnSearchProps("order_id"),
      },
      {
        title: "Merchant Info",
        dataIndex: "merchant_order_id",
        key: "merchant_order_id",
        width: "8%",
      },
      {
        title: "Store Info",
        dataIndex: "store_info",
        key: "store_info",
        width: "20%",
        ...this.getColumnSearchProps("store_info"),
      },
      {
        title: "Return Order Info",
        dataIndex: "return_info",
        key: "return_info",
        width: "20%",
        ...this.getColumnSearchProps("return_info"),
      },

      {
        title: "Order Time",
        dataIndex: "order_time",
        key: "order_time",
        width: "12%",
        ...this.getColumnSearchProps("order_time"),
      },

      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        width: "10%",
      },
    ];
    const { data, drivers } = this.state;
    const rows = [
      ...(data
        ? data?.map((item, id) =>
            this.createData(
              item.order_id,
              item.merchant_order_id,
              item?.shop?.name +
                "--" +
                item?.shop?.mobile_number +
                "--" +
                item?.shop?.address,
              item?.return_order_id?.order_id +
                "--" +
                item?.return_order_id?.recipient_name +
                "--" +
                item?.return_order_id?.recipient_mobile_number +
                "--" +
                item?.return_order_id?.recipient_address,
              this.makeLocalTime(item?.created_at),
              <Button
                size="large"
                type="primary"
                onClick={() => this.handleSubmit(item)}
              >
                Manual Return
              </Button>
            )
          )
        : ""),
    ];

    return (
      <>
        <Header
          title="Warehouse Parcel"
          subtitle="Return Parcels"
          name="return_order"
        />
        <br />

        {localStorage.getItem("invoice") ? (
          <Modal
            title="Invoice"
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            width="auto"
          >
            <div style={{ width: "35%", margin: "auto" }}>
              <PrintBarCode ref={(el) => (this.PrintBarCode = el)} />
              <span
                onClick={() => {
                  localStorage.removeItem("barcode");
                  localStorage.removeItem("invoice");
                  this.setState({ receipt_no: "" });
                }}
              >
                <ReactToPrint
                  trigger={() => (
                    <a
                      href="#"
                      style={{
                        position: "relative",
                        top: "20px",
                        backgroundColor: "#3498db",
                        padding: "12px 170px",
                        textDecoration: "none",
                        color: "#fff",
                      }}
                    >
                      Print this out!
                    </a>
                  )}
                  content={() => this.PrintBarCode}
                />
              </span>
              <br />
              <br />
              <br />
              <br />
              <a
                href="#"
                onClick={() => {
                  localStorage.removeItem("barcode");
                  localStorage.removeItem("invoice");
                  this.handleCancel();
                }}
                style={{
                  backgroundColor: "#e74c3c",
                  padding: "12px 200px",
                  textDecoration: "none",
                  color: "#fff",
                }}
              >
                Close
              </a>
              {/* <p onClick={this.printDocument}>Download</p> */}
              {/* <button onClick={this.printDocument}>Print</button> */}
            </div>
          </Modal>
        ) : null}
        <br />
        {rows?.length > 0 ? (
          <Table
            columns={columns}
            dataSource={rows}
            scroll={{ x: 1000 }}
            sticky
          />
        ) : (
          <Empty />
        )}
      </>
    );
  }
}

export default connect(null, {
  getWarehouseReturnOrder,
  fetchDrivers,
  confirmManualReturnOrder,
})(WarehouseReturn);
