import api from "./api";
import { LOADING, LOADED, ERROR_MESSAGE, SUCCESS_MESSAGE } from "./type";
import history from "../history";

export const fetchPending = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      "/get-panding-orders"
    );

    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data;
    }
    dispatch({ type: LOADED });
  } catch (err) {
    if (err.response) {
      if (err.response) {
        dispatch({ type: LOADED });
        if (err.response.status === 401) {
          localStorage.clear();
          history.push("/login");
        }
      }
    }

    // err.response.status === 402 ? console.log('===') : null;
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/error"),
    });
    dispatch({ type: LOADED });
  }
};

export const confirmParcel = (data) => async (dispatch) => {
  dispatch({ type: LOADING });
  const obj = {
    merchant_mobile_number: data?.merchant?.mobile_number,
    order_id: data?.order_id,
    recipient_mobile_number: data?.recipient_mobile_number,
    shop_id: data?.shop?.shop_id,
  };
  try {
    const response = await api(localStorage.getItem("token")).patch(
      "/confirm-order",
      { ...obj }
    );

    if (response.status === 201) {
      dispatch({ type: SUCCESS_MESSAGE, payload: "Parcel is confirmed. " });
      dispatch({ type: LOADED });
      return response.status;
    }
    // dispatch({type: FETCH_PENDING, payload: response.data});
  } catch (err) {
    if (err.response) {
      dispatch({ type: LOADED });
      if (err.response.status === 401) {
        localStorage.clear();
        history.push("/login");
      }
    }

    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/error"),
    });
    dispatch({ type: LOADED });
  }
};

export const fetchConfirmed = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      "/get-confirmed-orders"
    );
    if (response.status === 200) {
      dispatch({ type: LOADED });

      return response.data;
    }
  } catch (err) {
    if (err.response) {
      if (err.response) {
        dispatch({ type: LOADED });
        if (err.response.status === 401) {
          localStorage.clear();
          history.push("/login");
        }
      }
    }

    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/error"),
    });
    dispatch({ type: LOADED });
  }
};

export const pickupParcel = (data, rider) => async (dispatch) => {
  let d = rider;
  // dispatch({ type: LOADING });
  // console.log(data)

  // console.log(weight)
  // console.log(receipt_no)
  const obj = {
    merchant_mobile_number: data.merchant.mobile_number,
    order_id: data.order_id,
    recipient_mobile_number: data.recipient_mobile_number,
    shop_id: data.shop.shop_id,
    weight: data?.weight,
    receipt_no: data?.receipt_no,
    pickup_agent: d,
  };

  const oldI = localStorage.getItem("invoice")
    ? JSON.parse(localStorage.getItem("invoice"))
    : [];
  oldI.push({
    shop_name: data?.to === "in_city" ? data.shop.name : "amvines",
    shop_mobile:
      data?.to === "in_city" ? data.shop.mobile_number : "01818667279",
    merchant_order_id: data.merchant_order_id,
    customer_name: data.recipient_name,
    customer_phone: data.recipient_mobile_number,
    customer_address: data.recipient_address,
    product_description: data.item_description,
    weight_range: data.weight,
    amount_to_collect: data.charges.amount_to_collect,
    order_date: data.created_at,
    amvines_id: data.order_id,
    item: data.quantity,
  });

  try {
    const response = await api(localStorage.getItem("token")).patch(
      "/pickup-order",
      { ...obj }
    );

    if (response.status === 201) {
      dispatch({ type: SUCCESS_MESSAGE, payload: "Parcel is picked. " });
      localStorage.setItem("invoice", JSON.stringify(oldI));
      dispatch({ type: LOADED });
      return response.status;
    }
    dispatch({ type: LOADED });
  } catch (err) {
    if (err.response) {
      dispatch({ type: LOADED });
      if (err.response.status === 401) {
        localStorage.clear();
        history.push("/login");
      }
    }

    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/error"),
    });
    dispatch({ type: LOADED });
  }
};

export const fetchAssignAbleOrder = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      "/processable"
    );

    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      dispatch({ type: LOADED });
      if (err.response.status === 401) {
        localStorage.clear();
        history.push("/login");
      }
    }

    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/error"),
    });
    dispatch({ type: LOADED });
  }
};

export const assignDriver = (order_id, rider) => async (dispatch) => {
  let d = rider;
  dispatch({ type: LOADING });
  const obj = {
    order_id: order_id,
    delivery_agent: d,
  };

  try {
    const response = await api(localStorage.getItem("token")).patch(
      "/assign-to-driver",
      { ...obj }
    );

    if (response.status === 201) {
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: `Parcel is assigned to driver (ID ${d}). `,
      });
      return response.status;
    }
    dispatch({ type: LOADED });
  } catch (err) {
    if (err.response) {
      dispatch({ type: LOADED });
      if (err.response.status === 401) {
        localStorage.clear();
        history.push("/login");
      }
    }

    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/error"),
    });
    dispatch({ type: LOADED });
  }
};

export const fetchOutForDelivery = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      "/order/out-for-delivery"
    );

    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data.orders;
    }
  } catch (err) {
    if (err.response) {
      if (err.response) {
        dispatch({ type: LOADED });
        if (err.response.status === 401) {
          localStorage.clear();
          history.push("/login");
        }
      }
    }

    // err.response.status === 402 ? console.log('===') : null;
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/error"),
    });
    dispatch({ type: LOADED });
  }
};

export const fetchWarehouseOrder = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      "/warehouse-orders"
    );

    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      dispatch({ type: LOADED });
      if (err.response.status === 401) {
        localStorage.clear();
        history.push("/login");
      }
    }

    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/error"),
    });
    dispatch({ type: LOADED });
  }
};

// ======================================== Return Parcels ===========================
export const fetchSingleReturn =
  (order_id, delivery_agent) => async (dispatch) => {
    dispatch({ type: LOADING });
    try {
      const response = await api(localStorage.getItem("token")).get(
        `/get-return-order?order_id=${order_id}&delivery_agent=${delivery_agent}`
      );

      if (response.status === 200) {
        dispatch({ type: LOADED });
        localStorage.setItem("singleParcel", JSON.stringify(response.data));
        return response.status;
      }
    } catch (err) {
      if (err.response) {
        dispatch({ type: LOADED });
        if (err.response.status === 401) {
          localStorage.clear();
          history.push("/login");
        }
      }

      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response
          ? err.response.data.message
          : history.push("/error"),
      });
      dispatch({ type: LOADED });
    }
  };

export const fetchUpcomingReturn = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      "/should-return-order"
    );

    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      dispatch({ type: LOADED });
      if (err.response.status === 401) {
        localStorage.clear();
        history.push("/login");
      }
    }

    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/error"),
    });
    dispatch({ type: LOADED });
  }
};

export const pickupReturnedOrder =
  (order_id, delivery_agent) => async (dispatch) => {
    dispatch({ type: LOADING });
    const obj = {
      order_id: order_id,
      delivery_agent,
      delivery_agent: delivery_agent,
    };

    try {
      const response = await api(localStorage.getItem("token")).patch(
        "/return-order-collect",
        { ...obj }
      );

      if (response.status === 201) {
        localStorage.removeItem("singleParcel");
        dispatch({
          type: SUCCESS_MESSAGE,
          payload: "Return Parcel is picked. ",
        });
        dispatch({ type: LOADED });
        return response.status;
      }
    } catch (err) {
      if (err.response) {
        dispatch({ type: LOADED });
        if (err.response.status === 401) {
          localStorage.clear();
          history.push("/login");
        }
      }

      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response
          ? err.response.data.message
          : history.push("/error"),
      });
      dispatch({ type: LOADED });
    }
  };

export const fetchHoldParcel =
  (order_id, delivery_agent) => async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const response = await api(localStorage.getItem("token")).get(
        `/get-hold-order?order_id=${order_id}&delivery_agent=${delivery_agent}`
      );

      if (response.status === 200) {
        dispatch({ type: LOADED });
        return response.data;
      }

      dispatch({ type: LOADED });
    } catch (err) {
      if (err.response) {
        dispatch({ type: LOADED });
        if (err.response.status === 401) {
          localStorage.clear();
          history.push("/login");
        }
      }

      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response
          ? err.response.data.message
          : history.push("/error"),
      });
      dispatch({ type: LOADED });
    }
  };
export const pickupHoldOrder =
  (order_id, delivery_agent) => async (dispatch) => {
    dispatch({ type: LOADING });
    const obj = {
      order_id: order_id,
      delivery_agent: delivery_agent,
    };
    try {
      const response = await api(localStorage.getItem("token")).patch(
        "/hold-order-collect",
        { ...obj }
      );

      if (response.status === 201) {
        localStorage.removeItem("singleParcel");
        dispatch({ type: SUCCESS_MESSAGE, payload: "Hold Parcel is picked. " });
      }

      dispatch({ type: LOADED });
    } catch (err) {
      if (err.response) {
        dispatch({ type: LOADED });
        if (err.response.status === 401) {
          localStorage.clear();
          history.push("/login");
        }
      }

      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response
          ? err.response.data.message
          : history.push("/error"),
      });
      dispatch({ type: LOADED });
    }
  };

export const getWarehouseReturnOrder = () => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const response = await api(localStorage.getItem("token")).get(
      "/get-manual-return"
    );

    if (response.status === 200) {
      dispatch({ type: LOADED });

      return response.data.custom_return;
    }
  } catch (err) {
    if (err.response) {
      dispatch({ type: LOADED });
      if (err.response.status === 401) {
        localStorage.clear();
        history.push("/login");
      }
    }

    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/error"),
    });
    dispatch({ type: LOADED });
  }
};
export const confirmManualReturnOrder = (data) => async (dispatch) => {
  dispatch({ type: LOADING });

  const obj = {
    merchant: data.merchant?.mobile_number,
    order_id: data.order_id,
  };

  const invoice = {
    shop_name: data.shop.name,
    shop_mobile: data.shop.mobile_number,
    merchant_order_id: data.merchant_order_id,
    customer_name: data.recipient_name,
    customer_phone: data.recipient_mobile_number,
    customer_address: data.recipient_address,
    product_description: data.item_description,
    weight_range: data.weight,
    order_date: data.created_at,
    amvines_id: data.order_id,
    item: data.quantity,
  };

  try {
    const response = await api(localStorage.getItem("token")).post(
      "/confirm-manual-return",
      { ...obj }
    );

    if (response.status === 201) {
      dispatch({ type: SUCCESS_MESSAGE, payload: "Operation Successful ! " });
      localStorage.setItem("barcode", data.order_id);
      localStorage.setItem("invoice", JSON.stringify(invoice));
      dispatch({ type: LOADED });
      return response.status;
    }
    dispatch({ type: LOADED });
  } catch (err) {
    if (err.response) {
      dispatch({ type: LOADED });
      if (err.response.status === 401) {
        localStorage.clear();
        history.push("/login");
      }
    }

    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/error"),
    });
    dispatch({ type: LOADED });
  }
};

// =============================== Transit Order ========================
export const fetchPendingTransit = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      "/in-transit/pending"
    );

    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data.orders;
    }
  } catch (err) {
    if (err.response) {
      if (err.response) {
        dispatch({ type: LOADED });
        if (err.response.status === 401) {
          localStorage.clear();
          history.push("/login");
        }
      }
    }

    // err.response.status === 402 ? console.log('===') : null;
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/error"),
    });
    dispatch({ type: LOADED });
  }
};

export const receiveTOrder = (order_id) => async (dispatch) => {
  dispatch({ type: LOADING });

  const obj = {
    order_id: order_id,
  };

  try {
    const response = await api(localStorage.getItem("token")).patch(
      "/in-transit/receive",
      { ...obj }
    );

    if (response.status === 201) {
      dispatch({ type: LOADED });
      return response.status;
    }
  } catch (err) {
    if (err.response) {
      dispatch({ type: LOADED });
      if (err.response.status === 401) {
        localStorage.clear();
        history.push("/login");
      }
    }

    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/error"),
    });
    dispatch({ type: LOADED });
  }
};

export const sendTOrder = (order_id, warehouse, rider) => async (dispatch) => {
  // console.log(selectedOption.value)
  let w_id = warehouse;
  let d_id = rider;
  dispatch({ type: LOADING });
  const obj = {
    order_id: order_id,
    driver: d_id,
    warehouse: w_id,
  };
  try {
    const response = await api(localStorage.getItem("token")).patch(
      "/in-transit/send",
      { ...obj }
    );

    if (response.status === 201) {
      dispatch({ type: LOADED });
      return response.status;
    }
  } catch (err) {
    if (err.response) {
      dispatch({ type: LOADED });
      if (err.response.status === 401) {
        localStorage.clear();
        history.push("/login");
      }
    }

    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/error"),
    });
    dispatch({ type: LOADED });
  }
};

export const fetchSendOrder = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      "/intransitable"
    );

    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      dispatch({ type: LOADED });
      if (err.response.status === 401) {
        localStorage.clear();
        history.push("/login");
      }
    }

    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/error"),
    });
    dispatch({ type: LOADED });
  }
};

// ================================ ODD ===========================
export const fetchODDPending = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      "/odd/pending"
    );

    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data.orders;
    }
    dispatch({ type: LOADED });
  } catch (err) {
    if (err.response) {
      if (err.response) {
        dispatch({ type: LOADED });
        if (err.response.status === 401) {
          localStorage.clear();
          history.push("/login");
        }
      }
    }

    // err.response.status === 402 ? console.log('===') : null;
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/error"),
    });
    dispatch({ type: LOADED });
  }
};

export const fetchODDConfirmed = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      "/odd/confirm"
    );

    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data.orders;
    }
    dispatch({ type: LOADED });
  } catch (err) {
    if (err.response) {
      if (err.response) {
        dispatch({ type: LOADED });
        if (err.response.status === 401) {
          localStorage.clear();
          history.push("/login");
        }
      }
    }

    // err.response.status === 402 ? console.log('===') : null;
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/error"),
    });
    dispatch({ type: LOADED });
  }
};

export const confirmOddParcel = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const obj = {
    order: id,
  };
  try {
    const response = await api(localStorage.getItem("token")).patch(
      "/odd/confirm",
      { ...obj }
    );

    if (response.status === 201) {
      dispatch({ type: LOADED });
      return response.status;
    }
    dispatch({ type: LOADED });
  } catch (err) {
    if (err.response) {
      dispatch({ type: LOADED });
      if (err.response.status === 401) {
        localStorage.clear();
        history.push("/login");
      }
    }

    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/error"),
    });
    dispatch({ type: LOADED });
  }
};

export const assignOddDriver = (order_id, rider) => async (dispatch) => {
  // console.log(selectedOption.value)
  let d = rider;
  dispatch({ type: LOADING });
  const obj = {
    order: order_id,

    driver: d,
  };

  try {
    const response = await api(localStorage.getItem("token")).patch(
      "/odd/assign",
      { ...obj }
    );

    if (response.status === 201) {
      dispatch({ type: LOADED });
      return response.status;
    }
  } catch (err) {
    if (err.response) {
      dispatch({ type: LOADED });
      if (err.response.status === 401) {
        localStorage.clear();
        history.push("/login");
      }
    }

    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/error"),
    });
    dispatch({ type: LOADED });
  }
};

export const fetchOddProcessing = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      "/odd/processing"
    );

    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data.orders;
    }
    dispatch({ type: LOADED });
  } catch (err) {
    if (err.response) {
      dispatch({ type: LOADED });
      if (err.response.status === 401) {
        localStorage.clear();
        history.push("/login");
      }
    }

    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/error"),
    });
    dispatch({ type: LOADED });
  }
};

// ================= Third Party =====================\
export const sendThirdParyOrder = (order_id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const obj = {
    order_id: order_id,
  };
  try {
    const response = await api(localStorage.getItem("token")).post(
      "/eCourier/create",
      { ...obj }
    );

    if (response.status === 201) {
      dispatch({ type: LOADED });
      return response.status;
    }
  } catch (err) {
    if (err.response) {
      dispatch({ type: LOADED });
      if (err.response.status === 401) {
        localStorage.clear();
        history.push("/login");
      }
    }

    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/error"),
    });
    dispatch({ type: LOADED });
  }
};

export const fetchTPOrder = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      "/eCourier/order"
    );

    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data.orders;
    }
  } catch (err) {
    if (err.response) {
      dispatch({ type: LOADED });
      if (err.response.status === 401) {
        localStorage.clear();
        history.push("/login");
      }
    }

    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/error"),
    });
    dispatch({ type: LOADED });
  }
};

export const fetchTPPaymentPending = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      "/eCourier/payment/pending"
    );

    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      dispatch({ type: LOADED });
      if (err.response.status === 401) {
        localStorage.clear();
        history.push("/login");
      }
    }

    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/error"),
    });
    dispatch({ type: LOADED });
  }
};

export const TPDeliver = (order_id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const obj = {
    order_id: order_id,
  };
  try {
    const response = await api(localStorage.getItem("token")).patch(
      "/eCourier/order/deliver",
      { ...obj }
    );

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({ type: SUCCESS_MESSAGE, payload: "Transaction generated" });
      return response.status;
    }
  } catch (err) {
    if (err.response) {
      dispatch({ type: LOADED });
      if (err.response.status === 401) {
        localStorage.clear();
        history.push("/login");
      }
    }

    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/error"),
    });
    dispatch({ type: LOADED });
  }
};

export const TPReturn = (order_id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const obj = {
    order_id: order_id,
  };

  try {
    const response = await api(localStorage.getItem("token")).patch(
      "/eCourier/order/return",
      { ...obj }
    );

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({ type: SUCCESS_MESSAGE, payload: "Return generated" });
      return response.status;
    }
  } catch (err) {
    if (err.response) {
      dispatch({ type: LOADED });
      if (err.response.status === 401) {
        localStorage.clear();
        history.push("/login");
      }
    }

    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/error"),
    });
    dispatch({ type: LOADED });
  }
};
export const TPManualStatus = (state, order_id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const obj = {
    order_id: order_id,
    state: state,
  };
  try {
    const response = await api(localStorage.getItem("token")).patch(
      "/eCourier/order/manual/status",
      { ...obj }
    );

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({ type: SUCCESS_MESSAGE, payload: "Success" });
      return response.status;
    }
  } catch (err) {
    if (err.response) {
      dispatch({ type: LOADED });
      if (err.response.status === 401) {
        localStorage.clear();
        history.push("/login");
      }
    }

    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/error"),
    });
    dispatch({ type: LOADED });
  }
};

export const TPReturnList = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      "/eCourier/order/return/list"
    );

    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      dispatch({ type: LOADED });
      if (err.response.status === 401) {
        localStorage.clear();
        history.push("/login");
      }
    }

    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/error"),
    });
    dispatch({ type: LOADED });
  }
};

export const TPReturnPick = (data) => async (dispatch) => {
  // dispatch({ type: LOADING });
  const obj = {
    order_id: data?.return_order_id?.order_id,
  };
  const invoice = {
    shop_name: data.shop.name,
    shop_mobile: data.shop.mobile_number,
    merchant_order_id: data.merchant_order_id,
    customer_name: data.recipient_name,
    customer_phone: data.recipient_mobile_number,
    customer_address: data.recipient_address,
    product_description: data.item_description,
    weight_range: data.weight,
    order_date: data.created_at,
    amvines_id: data?.return_order_id?.order_id,
    item: data.quantity,
  };
  try {
    const response = await api(localStorage.getItem("token")).patch(
      "/eCourier/order/return/collect",
      { ...obj }
    );

    if (response.status === 201) {
      dispatch({ type: LOADED });
      localStorage.setItem("barcode", data?.return_order_id?.order_id);
      localStorage.setItem("invoice", JSON.stringify(invoice));
      return response.status;
    }
  } catch (err) {
    if (err.response) {
      dispatch({ type: LOADED });
      if (err.response.status === 401) {
        localStorage.clear();
        history.push("/login");
      }
    }

    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/error"),
    });
    dispatch({ type: LOADED });
  }
};
