import history from "../../history";
import React from "react";
import ThirdParty from "./thirdParty";

const normalOrder = [
  { name: "PENDING PARCELS", to: "/parcels-pending" },
  { name: "CONFIRMED PARCELS", to: "/parcels-confirmed" },
  { name: "PICK UP PARCELS", to: "/parcels-pickup" },
  { name: "ASSIGN PARCELS", to: "/parcels-assign" },
  { name: "OUT FOR DELIVERY", to: "/parcels-out-for-delivery" },
  { name: "WAREHOUSE PARCELS", to: "/parcels-warehouse" },
];
const returnOrder = [
  { name: "COLLECT RETURN PARCELS", to: "/parcels-return-pickup" },
  { name: "COLLECT HOLD PARCELS", to: "/parcels-hold-pickup" },
  { name: "UPCOMING RETURN PARCELS", to: "/parcels-upcoming-return" },
  { name: "WAREHOUSE RETURN PARCELS", to: "/parcels-warehouse-return" },
];
const transitOrder = [
  { name: "PENDING PARCELS", to: "/parcels-transit-pending" },
  { name: "SEND PARCELS", to: "/parcels-send" },
];

const ODDOrder = [
  { name: "PENDING PARCELS", to: "/parcels-odd-pending" },
  { name: "ASSIGN PARCELS", to: "/parcels-odd-assign" },
  { name: "PROCESSING PARCELS", to: "/parcels-odd-processing" },
];

const thridParyOder = [
  { name: "TRACK PARCELS", to: "/parcels-odd-pending" },
  { name: "SEND PARCELS", to: "/parcels-odd-assign" },
  { name: "COLLECT AMOUNT", to: "/collect-amount-tp" },
];

const Parcels = () => {
  const itemClassName =
    "p-3 bg-white hover:text-red-600 hover:shadow-xl hover:transition-all hover:translate-x-1 font-semibold cursor-pointer rounded-sm";

  const regularDeliver = () => (
    <div className="bg-white-400 text-center col-span-2 p-4 rounded-md">
      <h1 className="text-2xl font-extrabold text-center">Regular Delivery</h1>
      <div class="grid grid-rows-3 md:grid-rows-1 md:grid-cols-3 grid-flow-col gap-1 p-4 ">
        {/* ======= Normal Order ======= */}
        <div
          className="bg-#820374 rounded-sm"
          style={{ backgroundColor: "#820374" }}
        >
          <h1 className="p-4 text-xl font-bold ">Normal Order</h1>
          <div class="grid grid-rows-6 grid-flow-col gap-1 p-4 ">
            {normalOrder.map((item, id) => (
              <p
                className={itemClassName}
                onClick={() => history.push(item?.to)}
              >
                {item.name}
              </p>
            ))}
          </div>
        </div>

        {/* ========= Return order ========= */}
        <div className=" bg-red-300 rounded-sm">
          <h1 className="p-4 text-xl font-bold">Return Order</h1>
          <div class="grid grid-rows-6 grid-flow-col gap-1 p-4 ">
            {returnOrder.map((item, id) => (
              <p
                className={itemClassName}
                onClick={() => history.push(item?.to)}
              >
                {item.name}
              </p>
            ))}
          </div>
        </div>

        {/* ========== Transit Order ========= */}
        <div className="bg-indigo-500 rounded-sm">
          <h1 className="p-4 text-xl font-bold">Transit Order</h1>
          <div class="grid grid-rows-6 grid-flow-col gap-1 p-4 ">
            {transitOrder.map((item, id) => (
              <p
                className={itemClassName}
                onClick={() => history.push(item?.to)}
              >
                {item.name}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  const OnDayDelivery = () => (
    <div className="bg-cyan-500 p-4 rounded-md">
      <h1 className="text-2xl font-extrabold text-center">Same Day Delivery</h1>
      <div class="grid grid-rows-6 grid-flow-col gap-1 p-4">
        {ODDOrder.map((item, id) => (
          <p className={itemClassName} onClick={() => history.push(item?.to)}>
            {item.name}
          </p>
        ))}
      </div>
    </div>
  );

  const thirdParty = () => (
    <div className="bg-lime-400 p-4 rounded-md">
      <h1 className="text-2xl font-extrabold text-center">Third Party</h1>
      <div class="grid grid-rows-6 grid-flow-col gap-1 p-4">
        {thridParyOder.map((item, id) => (
          <p className={itemClassName} onClick={() => history.push(item?.to)}>
            {item.name}
          </p>
        ))}
      </div>
    </div>
  );
  return localStorage.getItem("thirdparty") !== "true" ? (
    <div
      class="grid grid-cols-1 md:grid-cols-3  gap-3 p-1 place-content-center bg-indigo-200"
      style={{ height: "auto" }}
    >
      {/* ========Regular Delivery========= */}
      {regularDeliver()}

      {/* ======= Same Day Delivery ========= */}
      {OnDayDelivery()}
    </div>
  ) : (
    <ThirdParty />
  );
};

export default Parcels;
