import React, { Fragment, useEffect, useState } from "react";

import { Disclosure, Menu, Transition } from "@headlessui/react";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import { Link, useLocation } from "react-router-dom";
import Logo from "./amvines-logo.png";
import { connect } from "react-redux";
import { signOut } from "../action/login";
import "./navbar.css";

const NavBar = ({ signOut }) => {
  const location = useLocation();
  let navigation = [];
  localStorage.getItem("thirdparty") == "true"
    ? (navigation = [
        // { name: 'DASHBOARD', to: '/', current: location.pathname == '/' ? true : false },
        {
          name: "PARCELS",
          to: "/parcels",
          current: location.pathname.slice("-").includes("/parcels")
            ? true
            : false,
        },
        {
          name: "COLLECT AMOUNT",
          to: "/collect-amount-tp",
          current: location.pathname === "/collect-amount-tp" ? true : false,
        },
        {
          name: "REPORT",
          to: "/report-tp",
          current: location.pathname === "/report-tp" ? true : false,
        },
      ])
    : (navigation = [
        // { name: 'DASHBOARD', to: '/', current: location.pathname == '/' ? true : false },
        {
          name: "PARCELS",
          to: "/parcels",
          current: location.pathname.slice("-").includes("/parcels")
            ? true
            : false,
        },
        {
          name: "RIDER",
          to: "/riders",
          current: location.pathname === "/riders" ? true : false,
        },
        {
          name: "COLLECT AMOUNT",
          to: "/collect-amount",
          current: location.pathname === "/collect-amount" ? true : false,
        },
        {
          name: "TRANSACTIONS",
          to: "/transactions",
          current: location.pathname === "/transactions" ? true : false,
        },
        {
          name: "REPORT",
          to: "/reports",
          current: location.pathname === "/reports" ? true : false,
        },
      ]);

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };
  return (
    <div className="bg-purple-800 sticky top-0 z-50">
      <Disclosure as="nav" className="bg-white-600">
        {({ open }) => (
          <>
            {/* ========== Desktop Menu ========= */}
            <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
              <div className="relative flex items-center justify-between h-16">
                {/* ==========Mobile menu button=========== */}
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon
                        className="block h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    ) : (
                      <MenuIcon
                        className="block h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    )}
                  </Disclosure.Button>
                </div>
                {/* =========== Logo and menu item ========== */}
                <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex-shrink-0 flex items-center">
                    <img
                      id="logo"
                      className="block lg:hidden w-28 h-20"
                      src={Logo}
                      alt="Workflow"
                    />
                    <img
                      className="hidden lg:block h-16 w-30"
                      style={{ width: "110px", height: "80px" }}
                      src={Logo}
                      alt="Workflow"
                    />
                  </div>

                  <div className="hidden sm:block sm:ml-6">
                    <div
                      className="flex space-x-4"
                      style={{
                        marginTop: "1.5em",
                        marginLeft: "1em",
                      }}
                    >
                      {navigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.to}
                          className={classNames(
                            item.current
                              ? "bg-gray-900 text-white"
                              : "text-white hover:bg-gray-700 hover:text-white",
                            "px-3 py-2 rounded-md text-sm font-medium "
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
                {/* ========= Right Bars ====== */}
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  <span className="text-white m-2 font-semibold">
                    {localStorage.getItem("warehouse_name")
                      ? localStorage.getItem("warehouse_name")
                      : "Warehouse"}
                  </span>
                  {localStorage.getItem("ac_balance") ? (
                    <span className="text-white m-2 font-semibold">
                      {" "}
                      Balance:{" "}
                      <strong>
                        {" "}
                        {localStorage.getItem("ac_balance")}
                      </strong> tk.{" "}
                    </span>
                  ) : null}
                  <button
                    type="button"
                    className="bg-gray-800 p-2 rounded-sm text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                    onClick={() => signOut()}
                  >
                    LOGOUT
                  </button>
                </div>
              </div>
            </div>
            {/* ========== Mobile Menu ========= */}
            <Disclosure.Panel className="sm:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.to}
                    className={classNames(
                      item.current
                        ? "bg-gray-900 text-white"
                        : "text-white hover:bg-gray-700 hover:text-white",
                      "block px-3 py-2 rounded-md text-base font-medium"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default connect(null, { signOut })(NavBar);
